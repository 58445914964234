<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE COUNTRY</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Country Name" v-model="country.nama_negara" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'country-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Country',
          disabled: false,
          href: '/admin/country',
        },
        {
          text: 'Update Country',
          disabled: true,
        },
      ],

      icons: {
        mdiSlashForward,
      },

      country: {
        nama_negara: '',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    CreateData(e) {
      console.log('NAMA ROLE : ' + this.country.nama_negara)
      if (this.$data.country.nama_negara.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Country name required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/negaras/store'

        this.axios
          .post(uri, this.country)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Country created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
          .catch(e => {
            // console.log(e);
            this.$swal.fire({
              title: 'Error!',
              text: 'Country failed to create!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'country-index' })
        }, 3000)
      }
    },
  },
}
</script>
